<template>
    <Sprints 
        :showHead="false"
        :pageName="page_name" />
</template>

<script>
import Sprints from '@apps/vue2TaskComponent/components/Sprint/Sprints.vue'
export default {
    components: {
        Sprints
    },
    props: {
        page_name: {
            type: String,
            required: true
        }
    }
}
</script>